<template>
  <div class="video">
    <level-nav :currentLevel=level @changeLevel="changeLevel"></level-nav>
    <div class="video__title">
      <h2 class="video__title__main">Nghề nghiệp</h2>
      <p class="video__title__sub" v-if="!isGetting && newOccupationMovies.length > 0"
        @click="$router.push({ name: 'Search', query: { uid: uid, levels: level, topics: '', occupations: 'all' }})">Xem tất cả</p>
    </div>
    <video-list v-if="!isGetting" :movies=newOccupationMovies></video-list>
    <div class="video__title--topic">
      <h2 class="video__title__main">Chủ đề</h2>
      <p class="video__title__sub" v-if="!isGetting && newTopicMovies.length > 0"
        @click="$router.push({ name: 'Search', query: { uid: uid, levels: level, topics: 'all', occupations: '' }})">Xem tất cả</p>
    </div>
    <video-list v-if="!isGetting" :movies=newTopicMovies></video-list>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import LevelNav from '@/components/video/nav'
import VideoList from '@/components/video/list'

import CommonFunction from '@/lib/common'

export default {
  components: { LevelNav, VideoList },
  extends: CommonFunction,
  data () {
    return {
      // 日本語レベル
      level: 'N5',
      // 必要なデータを取得中か
      isGetting: true
    }
  },
  mounted () {
    // 最新5件の職業とトピックの動画、講師一覧を取得する
    Promise.all([
      this.addNewOccupationMovies('N5'),
      this.addNewOccupationMovies('N4'),
      this.addNewOccupationMovies('N3'),
      this.addNewOccupationMovies('N2'),
      this.addNewTopicMovies('N5'),
      this.addNewTopicMovies('N4'),
      this.addNewTopicMovies('N3'),
      this.addNewTopicMovies('N2')
    ]).then(() => {
      this.isGetting = false
    })
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {Array} 最新の職業タグが付与された動画一覧
     */
    newOccupationMovies () {
      // 処理中の場合は空配列を返却
      if (this.isGetting) return []

      // 取得したら配列にして作成日順にソートして返却
      const results = []
      const movies = this.getNewOccupationMovies()(this.level)
      Object.keys(movies).forEach(key => {
        results.push(Object.assign({ id: key }, movies[key]))
      })
      return results.sort(this.sortObjectKey('createdAt', 'desc'))
    },
    /**
     * @return {Array} 最新のトピックタグが付与された動画一覧
     */
    newTopicMovies () {
      // 処理中の場合は空配列を返却
      if (this.isGetting) return []

      // 取得したら配列にして作成日順にソートして返却
      const results = []
      const movies = this.getNewTopicMovies()(this.level)
      Object.keys(movies).forEach(key => {
        results.push(Object.assign({ id: key }, movies[key]))
      })
      return results.sort(this.sortObjectKey('createdAt', 'desc'))
    }
  },
  methods: {
    ...mapGetters('movie', ['getNewOccupationMovies', 'getNewTopicMovies']),
    ...mapActions('movie', ['addNewOccupationMovies', 'addNewTopicMovies']),
    /**
     * 日本語レベルの変更
     * @param {String} level 日本語レベル
     */
    changeLevel (level) {
      this.level = level
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.video {
  margin-bottom: $footer_height;
  padding: 0 $main_padding;
  &__title {
    margin-top: 20px;
    &--topic {
      @extend .video__title;
      margin-top: 30.5px;
    }
    &__main {
      display: inline-block;
      vertical-align: bottom;
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      color: #393939;
    }
    &__sub {
      display: inline-block;
      vertical-align: bottom;
      float: right;
      margin: 0;
      font-size: 15px;
      color: #ff9534;
    }
  }
}
</style>
