<template>
  <div class="list">
    <div v-if="movies.length === 0 && !isFavoritePage" class="list__none">
      <img class="list__none__img" src="@/assets/img/settings.svg">
      <p class="list__none__text">Xin lỗi, chúng tôi đang cài đặt hệ thống.<br>Xin vui lòng đợi trong giây lát.</p>
    </div>
    <div v-else-if="movies.length === 0 && isFavoritePage" class="list__none--favorite">
      <img class="list__none__img--favorite" src="@/assets/img/settings-gray.svg">
      <p class="list__none__text--favorite">Bạn chưa thêm nội dung gì cả.</p>
    </div>
    <div class="list__elem" v-for="movie in movies" :key="movie.id">
      <div class="list__elem__thumb" @click="$router.push({ name: 'VideoDetail', params: { id: movie.id }, query: { uid: uid } })">
        <img class="list__elem__thumb__img" :src="movie.thumb">
        <div class="list__elem__thumb__time">{{ formatedDuration(movie.duration) }}</div>
      </div>
      <div class="list__elem__text">
        <p class="list__elem__text__title" @click="$router.push({ name: 'VideoDetail', params: { id: movie.id }, query: { uid: uid } })">{{ movie.name }}</p>
        <p class="list__elem__text__teacher" @click="$router.push({ name: 'VideoDetail', params: { id: movie.id }, query: { uid: uid } })">{{ teacherName(movie.teacher) }}</p>
        <div class="list__elem__text__tag"
        v-for="level in movie.levels" :key="movie.id + level"
        @click="$router.push({ name: 'Search', query: { uid: uid, levels: level, topics: '', occupations: '', from: 'tag' }})">{{ level }}</div>
        <div class="list__elem__text__tag"
        v-for="occupation in movie.occupations" :key="movie.id + occupation"
        @click="$router.push({ name: 'Search', query: { uid: uid, levels: '', topics: '', occupations: occupation, from: 'tag' }})">{{ occupation }}</div>
        <div class="list__elem__text__tag"
        v-for="topic in movie.topics" :key="movie.id + topic"
        @click="$router.push({ name: 'Search', query: { uid: uid, levels: '', topics: topic, occupations: '', from: 'tag' }})">{{ topic }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    // 表示する動画一覧
    movies: {
      type: Array,
      required: true
    }
  },
  computed: {
    /**
     * @return {String} ユーザーID
     */
    uid () {
      return this.$store.getters['user/uid']
    },
    /**
     * @return {Boolean} お気に入りページかどうか
     */
    isFavoritePage () {
      return this.$route.name === 'Favorite'
    }
  },
  methods: {
    ...mapGetters('teacher', ['getTeacher']),
    /**
     * @param {Number} duration 総再生時間
     * @return {String} mm:ss
     */
    formatedDuration (duration) {
      const mm = Math.floor(duration / 60)
      const ss = duration - mm * 60
      return ('00' + mm).slice(-2) + ':' + ('00' + ss).slice(-2)
    },
    /**
     * @param {String} id 講師のドキュメントID
     * @return {String} 講師の名前
     */
    teacherName (id) {
      return this.getTeacher()(id).name
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.list {
  width: 100%;
  &__elem {
    padding: 11px 0;
    border-bottom: 1px solid #e0e0e0;
    &__thumb {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 164px;
      font-size: 0;
      text-align: center;
      &__img {
        height: 98px;
        width: 100%;
      }
      &__time {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 16px;
        width: 36px;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        background-color: #151414;
        font-size: 9px;
        color: white;
        line-height: 16px;
      }
    }
    &__text {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 170px);
      padding-left: 12px;
      font-size: 0;
      box-sizing: border-box;
      &__title {
        width: 100%;
        margin: 0;
        font-size: 15px;
        font-weight: bold;
        color: #393939;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &__teacher {
        width: 100%;
        margin: 0;
        margin-bottom: 8px;
        font-size: 15px;
        color: #707070;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      &__tag {
        display: inline-block;
        vertical-align: top;
        height: 14px;
        min-width: 42px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 0 8px;
        border-radius: 20px;
        background-color: #a5a5a5;
        font-size: 10px;
        line-height: 14px;
        color: white;
        text-align: center;
        box-sizing: border-box;
      }
    }
  }
  &__none {
    height: 160px;
    width: 100%;
    margin-top: 9px;
    padding: 42.8px 18px;
    background-color: #d7d7d7;
    box-sizing: border-box;
    &--favorite {
      width: 100%;
      margin-top: 80px;
      text-align: center;
    }
    &__img {
      display: inline-block;
      vertical-align: middle;
      height: auto;
      width: 75.5px;
      &--favorite {
        height: auto;
        width: 151.1px;
      }
    }
    &__text {
      display: inline-block;
      vertical-align: middle;
      max-width: calc(100% - 18px - 18px - 75.5px - 9px);
      margin: 0;
      margin-left: 9px;
      font-size: 15px;
      font-weight: bold;
      color: white;
      &--favorite {
        margin: 0;
        margin-left: 9px;
        font-size: 15px;
        font-weight: bold;
        color: #d4d4d4;
      }
    }
  }
}
</style>
