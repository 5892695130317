<template>
  <ul class="nav">
    <li v-for="level in levels" :key=level class="nav__level" @click="$emit('changeLevel', level)">
      <span :class="level === currentLevel ? 'nav__level__text--active' : 'nav__level__text'">{{ level }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    currentLevel: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      // 日本語レベル一覧
      levels: ['N5', 'N4', 'N3', 'N2']
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/sass/valiables.scss';

.nav {
  padding: 0;
  list-style: none;
  &__level {
    display: inline-block;
    margin-right: 20px;
    &__text {
      font-size: 20px;
      font-weight: bold;
      color: #a5a5a5;
      &--active {
        @extend .nav__level__text;
        color: black;
        border-bottom: 3px solid #ff9534;
      }
    }
  }
}
</style>
